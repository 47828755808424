<template>
  <div>
    <h3 class="text-center text-bold pb-4">УП-6195</h3>
    <b-card>
      <h5> О социальной поддержке престарелых и лиц с инвалидностью,
        а также дальнейшем развитии системы домов интернатов "Саховат" и "Мурувват"</h5>
    </b-card>
    <div class="justify-content-between">
      <b-row class="flex justify-content-between w-10/12 mx-auto ml-5">
        <b-col md="4">
          <div>
            <v-select :options="categories" label="name" v-model="category"></v-select>
          </div>
        </b-col>
        <b-col md="4">
          <b-button class="btn-success ml-5">Смотреть на lex.uz</b-button>
          <span><b-icon icon="file-earmark-break" font-scale="4" class="pl-2"></b-icon></span>
        </b-col>
      </b-row>
        <div class="w-100 mt-3">
          <b-table striped hover :items="items" :fields="fields" responsive>
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{data.item.id}}</span>
            </template>
            <template #cell(act)="data">
              <b-button variant="primary" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="edit" class="text-white"></b-icon>
              </b-button>
            </template>
          </b-table>
        </div>
      </div>
  </div>
</template>

<script>
import { fetchUsersList } from '@/api/auth'
import vSelect from 'vue-select'
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    vSelect,
  },
  name: 'SubCompleted',
  data() {
    return {
      methods:{
        handleEdit(id) {
          this.$router.push({name: 'home', params: { id } })
        },
      },
      items: [
        { id: 1, name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений', user: 'Начальник отдела координации' +
              ' внедрения информационно коммуникационных технологий', date: '04.06.2021',
        status: 'completed', adder:'Главный специалист министерство ' +
              'народного образование', update:'03.06.2021 15:30', act:1},
        { id: 2,
          name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений',
          user: 'Начальник отдела координации ' +
              'внедрения информационно коммуникационных технологий',
          date: '04.06.2021',
        status: 'incompleted',
          adder:'Главный специалист министерство ' +
              'народного образование',
          update:'03.06.2021 15:30',
          act:1},
        { id: 3,
          name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений',
          user: 'Начальник отдела координации ' +
              'внедрения информационно коммуникационных технологий',
          date: '04.06.2021',
        status: 'waiting',
          adder:'Главный специалист министерство ' +
              'народного образование',
          update:'03.06.2021 15:30',
          act:1},
        { id: 4,
          name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений',
          user: 'Начальник отдела координации' +
              ' внедрения информационно коммуникационных технологий',
          date: '04.06.2021',
        status: 'completed',
          adder:'Главный специалист министерство ' +
              'народного образование',
          update:'03.06.2021 15:30',
          act:1},
        { id: 5,
          name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений',
          user: 'Начальник отдела координации' +
              ' внедрения информационно коммуникационных технологий',
          date: '04.06.2021',
        status: 'completed',
          adder:'Главный специалист министерство ' +
              'народного образование',
          update:'03.06.2021 15:30',
          act:1},
        { id: 6,
          name: 'Образовать Агентство ' +
              'по развитию медико-социальных услуг' +
              ' Республики Узбекистан (далее — Агентство) и его ' +
              'территориальные подразделения на базе Агентства ' +
              'медико-социальных услуг при ' +
              'Министерстве здравоохранения Республики ' +
              'Узбекистан и его территориальных подразделений',
          user: 'Начальник отдела координации' +
              ' внедрения информационно коммуникационных технологий',
          date: '04.06.2021',
          status: 'incompleted',
          adder:'Главный специалист министерство ' +
              'народного образование',
          update:'03.06.2021 15:30',
          act:1},
      ],
      categories: [
        { id: 1, name: 'One' },
        { id: 2 ,name: 'Two' },
        { id: 1 ,name: 'Three' },
      ],
      category: null,
    }
  },
  created() {
    fetchUsersList()
  },
}

</script>

<style scoped>

</style>
